import { HubConnectionBuilder, LogLevel, HubConnectionState } from '@microsoft/signalr';

var lastKey = 0;

export default {
    install(Vue) {
        let nextRegistrationId = 1;
        let reconnectEnabled = true;
        let commsEngineStartCallbacks = {};
        let smsStartCallbacks = [];
        let jobStartCallbacks = [];
        let pubSubStartCallbacks = [];
        let shopOrderStartCallbacks = [];
        let loggerStartCallbacks = [];
        let notificationStartCallbacks = [];
        let loggerStartCallbackDictionary = {};

        let connectingUserId = null;
        let connectingWorkingClientId = null;

        let reconnectionMs = 2000;
        var lockResolver;

        function getToken() { 
            //this.$debug.log("call to signalr getToken");
            var token = Vue.prototype.$auth.token();
            //console.log("token: " + token);
            return token;
        }

        function setupLock() {
            // This should stop the browser deciding the tab is sleeping and disconnect signalr
            // https://docs.microsoft.com/en-us/aspnet/core/signalr/javascript-client?view=aspnetcore-5.0#bsleep

            if (navigator && navigator.locks && navigator.locks.request) {
                const promise = new Promise((res) => {
                    lockResolver = res;
                });

                navigator.locks.request('kms-sk-signalr-lock', { mode: "shared" }, () => {
                    return promise;
                });
            }
        }

        async function startLoggerConnection() {
            if (loggerConnection.state != HubConnectionState.Disconnected)
                return;
            try {
                await loggerConnection.start();
                console.assert(loggerConnection.state === HubConnectionState.Connected);
                runStartLoggerCallbacks();
            } catch (err) {
                console.assert(loggerConnection.state === HubConnectionState.Disconnected);
                console.error('Failed to connect with logger hub', err);
                setTimeout(() => startLoggerConnection(), reconnectionMs);
            }
        }

        async function startNotificationConnection() {
            if (notificationConnection.state != HubConnectionState.Disconnected)
                return;
            try {
                await notificationConnection.start();
                console.assert(notificationConnection.state === HubConnectionState.Connected);
                runStartNotificationCallbacks();
            } catch (err) {
                console.assert(notificationConnection.state === HubConnectionState.Disconnected);
                console.error('Failed to connect with notification hub', err);
                setTimeout(() => startNotificationConnection(), reconnectionMs);
            }
        }

        async function startCommsEngineConnection() {
            if (commsEngineConnection.state != HubConnectionState.Disconnected)
                return;
            try {
                await commsEngineConnection.start();
                console.assert(commsEngineConnection.state === HubConnectionState.Connected);
                runStartCommsEngineCallbacks();
            } catch (err) {
                console.assert(commsEngineConnection.state === HubConnectionState.Disconnected);
                console.error('Failed to connect with comms engine hub', err);
                setTimeout(() => startCommsEngineConnection(), reconnectionMs);
            }
        }

        async function startPubSubConnection() {
            if (pubSubConnection.state != HubConnectionState.Disconnected)
                return;
            try {
                await pubSubConnection.start();
                console.assert(pubSubConnection.state === HubConnectionState.Connected);
                runStartPubSubCallbacks();
            } catch (err) {
                console.assert(pubSubConnection.state === HubConnectionState.Disconnected);
                console.error('Failed to connect with pub sub hub', err);
                setTimeout(() => startPubSubConnection(), reconnectionMs);
            }
        }

        async function startSmsConnection() {
            if (smsConnection.state != HubConnectionState.Disconnected)
                return;
            try {
                await smsConnection.start();
                console.assert(smsConnection.state === HubConnectionState.Connected);
                runStartSmsCallbacks();
            } catch (err) {
                console.assert(smsConnection.state === HubConnectionState.Disconnected);
                console.error('Failed to connect with sms hub', err);
                setTimeout(() => startSmsConnection(), reconnectionMs);
            }
        }

        async function startJobConnection() {
            if (jobConnection.state != HubConnectionState.Disconnected)
                return;
            try {
                await jobConnection.start();
                console.assert(jobConnection.state === HubConnectionState.Connected);
                runStartJobCallbacks();
            } catch (err) {
                console.assert(jobConnection.state === HubConnectionState.Disconnected);
                console.error('Failed to connect with job hub', err);
                setTimeout(() => startJobConnection(), reconnectionMs);
            }
        }

        async function startShopOrderConnection() {
            if (shopOrderConnection.state != HubConnectionState.Disconnected)
                return;
            try {
                await shopOrderConnection.start();
                console.assert(shopOrderConnection.state === HubConnectionState.Connected);
                runStartShopOrderCallbacks();
            } catch (err) {
                console.assert(shopOrderConnection.state === HubConnectionState.Disconnected);
                console.error('Failed to connect with shop order hub', err);
                setTimeout(() => startShopOrderConnection(), reconnectionMs);
            }
        }

        async function handleCommsEngineConnectionClose() {
            if (reconnectEnabled)
                await startCommsEngineConnection();
        }

        async function handlePubSubConnectionClose() {
            if (reconnectEnabled)
                await startPubSubConnection();
        }

        async function handleSmsConnectionClose() {
            if (reconnectEnabled)
                await startSmsConnection();
        }

        async function handleJobConnectionClose() {
            if (reconnectEnabled)
                await startJobConnection();
        }

        async function handleShopOrderConnectionClose() {
            if (reconnectEnabled)
                await startShopOrderConnection();
        }

        async function handleLoggerConnectionClose() {
            if (reconnectEnabled)
                await startLoggerConnection();
        }

        async function handleNotificationConnectionClose() {
            if (reconnectEnabled)
                await startNotificationConnection();
        }

        async function handleAuthUserChange(newUser, oldUser) {
            // Detect change of user ID
            var oldUserId = connectingUserId;
            var newUserId = newUser && newUser.user && newUser.user.userId ? newUser.user.userId : null;
            connectingUserId = newUserId;

            // Detect change of working client ID
            var oldWorkingClientId = connectingWorkingClientId;
            var newWorkingClientId = newUser && newUser.userData && newUser.userData.workingClientId ? newUser.userData.workingClientId : null;
            connectingWorkingClientId = newWorkingClientId;

            if (
                (oldUserId && (!newUserId || newUserId != oldUserId)) ||
                (oldWorkingClientId && (!newWorkingClientId || newWorkingClientId != oldWorkingClientId))
            ) {
                // no this.$debug on prod
                //this.$debug.log(`Stopping SignalR connections for user #${oldUserId} working on client #${oldWorkingClientId}`);
                reconnectEnabled = false;
                await commsEngineConnection.stop();
                await pubSubConnection.stop();
                await jobConnection.stop();
                await smsConnection.stop();
                await shopOrderConnection.stop();
                await loggerConnection.stop();
                await notificationConnection.stop();
            }

            if (
                (newUserId && (!oldUserId || oldUserId != newUserId)) ||
                (newWorkingClientId && (!oldWorkingClientId || oldWorkingClientId != newWorkingClientId))
            ) {
                // no this.$debug on prod
                //this.$debug.log(`Starting SignalR connections for user #${newUserId} working on client #${newWorkingClientId}`);
                reconnectEnabled = true;
                await startCommsEngineConnection();
                await startPubSubConnection();
                await startSmsConnection();
                await startJobConnection();
                await startShopOrderConnection();
                await startLoggerConnection();
                if (process.env.VUE_APP_PROJECT_ACS == "true") {
                    await startNotificationConnection();
                }
            }
        }

        function runStartCommsEngineCallbacks() {
            for (var registrationId in commsEngineStartCallbacks)
                commsEngineStartCallbacks[registrationId].callback();
        }

        function runStartPubSubCallbacks() {
            for (var i = 0; i < pubSubStartCallbacks.length; i++)
                pubSubStartCallbacks[i]();
        }

        function runStartSmsCallbacks() {
            for (var i = 0; i < smsStartCallbacks.length; i++)
                smsStartCallbacks[i]();
        }

        function runStartJobCallbacks() {
            for (var i = 0; i < jobStartCallbacks.length; i++)
                jobStartCallbacks[i]();
        }

        function runStartShopOrderCallbacks() {
            for (var i = 0; i < shopOrderStartCallbacks.length; i++)
                shopOrderStartCallbacks[i]();
        }

        function runStartLoggerCallbacks() {
            for (var key in loggerStartCallbackDictionary)
                loggerStartCallbackDictionary[key]();
        }

        function runStartNotificationCallbacks() {
            for (var i = 0; i < notificationStartCallbacks.length; i++)
                notificationStartCallbacks[i]();
        }

        function registerSmsCallback(callback) {
            smsStartCallbacks.push(callback);
            if (smsConnection.state == HubConnectionState.Connected) {
                callback();
            }
        }

        function registerJobCallback(callback) {
            jobStartCallbacks.push(callback);
            if (jobConnection.state == HubConnectionState.Connected) {
                callback();
            }
        }

        function registerCommsEngineCallback(callback, signal) {
            var registration = {
                id: nextRegistrationId++,
                callback: callback,
                signal: signal,
            };
            commsEngineStartCallbacks[registration.id] = registration;
            if (signal) {
                signal.addEventListener('abort', () => unregisterCommsEngineCallback(registration.id));
            }
            if (commsEngineConnection.state == HubConnectionState.Connected) {
                callback();
            }
            return registration.id;
        }

        function unregisterCommsEngineCallback(registrationId) {
            if (registrationId && registrationId in commsEngineStartCallbacks)
                delete commsEngineStartCallbacks[registrationId];
        }

        function registerPubSubCallback(callback) {
            pubSubStartCallbacks.push(callback);
            if (pubSubConnection.state == HubConnectionState.Connected) {
                callback();
            }
        }

        function registerShopOrderCallback(callback) {
            shopOrderStartCallbacks.push(callback);
            if (shopOrderConnection.state == HubConnectionState.Connected) {
                callback();
            }
        }

        function registerLoggerCallback(callback, key) {
            if (!key) {
                key = `unkeyed-${++lastKey}`;
            }
            if (!(key in loggerStartCallbackDictionary)) {
                loggerStartCallbackDictionary[key] = callback;
            }
            if (shopOrderConnection.state == HubConnectionState.Connected) {
                callback();
            }
            return key;
        }
        function unregisterLoggerCallback(unregisterCallback, key) {
            if (key && (key in loggerStartCallbackDictionary)) {
                delete loggerStartCallbackDictionary[key];
            }
            if (shopOrderConnection.state == HubConnectionState.Connected) {
                unregisterCallback();
            }
        }

        function registerNotificationCallback(callback) {
            notificationStartCallbacks.push(callback);
            if (notificationConnection.state == HubConnectionState.Connected) {
                callback();
            }
        }

        function registerNotificationCallback(callback) {
            notificationStartCallbacks.push(callback);
            if (notificationConnection.state == HubConnectionState.Connected) {
                callback();
            }
        }

        function handleCommsEngineConnectionReconnecting(error) {
            Vue.notify({ type: 'alert-warning', text: `Reconnecting to signalr...` + error });
            //this.$emit('signalr-disconnected');
        }

        function handleCommsEngineConnectionReconnected() {
            Vue.notify({ type: 'alert-success', text: `Reconnected to signalr` });
            runStartCommsEngineCallbacks();
            // this.$emit('signalr-connected');
        }



        function reconnectNewAuthToken(token) {

        }

        const commsEngineConnection = new HubConnectionBuilder()
            .withUrl(process.env.VUE_APP_SIGNALR_URL + "/commsenginehub", { accessTokenFactory: getToken })
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: retryContext => {
                    return reconnectionMs;
                }
            })
            .configureLogging(LogLevel.Warning)
            .build();


        const jobConnection = new HubConnectionBuilder()
            .withUrl(process.env.VUE_APP_SIGNALR_URL + "/jobprocessorhub", { accessTokenFactory: getToken })
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: retryContext => {
                    return reconnectionMs;
                }
            })
            .configureLogging(LogLevel.Warning)
            .build();

        const smsConnection = new HubConnectionBuilder()
            .withUrl(process.env.VUE_APP_SIGNALR_URL + "/smshub", { accessTokenFactory: getToken })
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: retryContext => {
                    return reconnectionMs;
                }
            })
            .configureLogging(LogLevel.Warning)
            .build();

        const pubSubConnection = new HubConnectionBuilder()
            .withUrl(process.env.VUE_APP_SIGNALR_URL + "/pubsubhub", { accessTokenFactory: getToken })
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: retryContext => {
                    return reconnectionMs;
                }
            })
            .configureLogging(LogLevel.Warning)
            .build();

        const shopOrderConnection = new HubConnectionBuilder()
            .withUrl(process.env.VUE_APP_SIGNALR_URL + "/shoporderhub", { accessTokenFactory: getToken })
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: retryContext => {
                    return reconnectionMs;
                }
            })
            .configureLogging(LogLevel.Warning)
            .build();

        const loggerConnection = new HubConnectionBuilder()
            .withUrl(process.env.VUE_APP_SIGNALR_URL + "/loggerhub", { accessTokenFactory: getToken })
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: retryContext => {
                    return reconnectionMs;
                }
            })
            .configureLogging(LogLevel.Warning)
            .build();

        const notificationConnection = new HubConnectionBuilder()
            .withUrl(process.env.VUE_APP_SIGNALR_URL + "/notificationhub", { accessTokenFactory: getToken })
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: retryContext => {
                    return reconnectionMs;
                }
            })
            .configureLogging(LogLevel.Warning)
            .build();

        commsEngineConnection.onclose(handleCommsEngineConnectionClose);
        //commsEngineConnection.onreconnecting(handleCommsEngineConnectionReconnecting);
        //commsEngineConnection.onreconnected(handleCommsEngineConnectionReconnected);

        pubSubConnection.onclose(handlePubSubConnectionClose);
        smsConnection.onclose(handleSmsConnectionClose);
        jobConnection.onclose(handleJobConnectionClose);
        shopOrderConnection.onclose(handleShopOrderConnectionClose);
        loggerConnection.onclose(handleLoggerConnectionClose);
        notificationConnection.onclose(handleNotificationConnectionClose);

        Vue.prototype.$smsHub = smsConnection;
        Vue.prototype.$smsHubStartRegistration = registerSmsCallback;

        Vue.prototype.$jobHub = jobConnection;
        Vue.prototype.$jobHubStartRegistration = registerJobCallback;

        Vue.prototype.$commsEngineHub = commsEngineConnection;
        Vue.prototype.$commsEngineHubStartRegistration = registerCommsEngineCallback;
        Vue.prototype.$commsEngineHubEndRegistration = unregisterCommsEngineCallback;

        Vue.prototype.$pubSubHub = pubSubConnection;
        Vue.prototype.$pubSubHubStartRegistration = registerPubSubCallback;

        Vue.prototype.$shopOrderHub = shopOrderConnection;
        Vue.prototype.$shopOrderHubStartRegistration = registerShopOrderCallback;

        Vue.prototype.$loggerHub = loggerConnection;
        Vue.prototype.$loggerHubStartRegistration = registerLoggerCallback;
        Vue.prototype.$loggerHubEndRegistration = unregisterLoggerCallback;

        Vue.prototype.$notificationHub = notificationConnection;
        Vue.prototype.$notificationHubStartRegistration = registerNotificationCallback;
   


        setupLock();

        Vue.mixin({
            created() {
                if (!this.$parent) {
                    // This is either the root component or a component
                    // created with `new` and no parent

                    //this.$watch(() => this.$auth.check(), handleAuthChange);
                    //this.$watch('$store.state.browsingClientIdChangeComplete', reconnect);
                    // Watch on this.$auth.user() can react to both auth and browsing client changes
                    this.$watch(() => this.$auth.user(), handleAuthUserChange);
                }
            }
        });
    }
}
