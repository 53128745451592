import { startCase } from 'lodash';
import axios from 'axios'

export let uniqueClientNameValidator = {
    params: ['clientId'],
    validate(value, { clientId }) {
        var existingId = clientId ? `&client_id=${clientId}` : '';
        return axios.get(`/client/validate-name?name=${encodeURIComponent(value)}${existingId}`)
            .then(r => r.data)
            .catch(r => false);
    },
    message: 'Name is not unique',

}

export let uniqueEmailValidator = {
    params: ['userId'],
    validate(value, { userId }) {
        var existingId = userId ? `&userId=${userId}` : '';
        return axios.get(`/user/validate-email?email=${encodeURIComponent(value)}${existingId}`)
            .then(r => r.data)
            .catch(r => false);
    },
    message: 'Email is not unique',

}

export let uniqueCloudTokenPrefixValidator = {
    params: ['clientId'],
    validate(value, { clientId }) {
        var existingId = clientId ? `&client_id=${clientId}` : '';
        return axios.get(`/client/validate-cloud-token-prefix?prefix=${encodeURIComponent(value)}${existingId}`)
            .then(r => r.data)
            .catch(r => false);
    },
    message: 'Cloud Token Prefix is not unique',

}

export let uniqueDoorNumberApex = {
    params: ['controllerId', 'doorEntryLinkDoorId'],
    validate(value, { controllerId, doorEntryLinkDoorId }) {
        var existingId = doorEntryLinkDoorId ? `&door_entry_link_door_id=${doorEntryLinkDoorId}` : '';
        return axios.get(`/doorEntryLinkDoor/validate-number?number=${encodeURIComponent(value)}&controller_id=${encodeURIComponent(controllerId)}${existingId}`)
            .then(r => r.data)
            .catch(r => false);
    },
    message: 'Door number is not unique'
}

export let uniqueDoorDescriptionApex = {
    params: ['controllerId', 'doorEntryLinkDoorId'],
    validate(value, { controllerId, doorEntryLinkDoorId }) {
        var existingId = doorEntryLinkDoorId ? `&door_entry_link_door_id=${doorEntryLinkDoorId}` : '';
        return axios.get(`/doorEntryLinkDoor/validate-description?description=${encodeURIComponent(value)}&controller_id=${encodeURIComponent(controllerId)}${existingId}`)
            .then(r => r.data)
            .catch(r => false);
    },
    message: 'Door description is not unique'
}


export let uniqueUsernameValidator = {
    params: ['userId'],
    validate(value, { userId }) {
        var existingId = userId ? `&userId=${userId}` : '';
        return axios.get(`/user/validate-username?username=${encodeURIComponent(value)}${existingId}`).then(r => r.data).catch(r => false);
    },
    message: 'Username is not unique'
}

export let uniqueDomainValidator = {
    params: ['clientId'],
    validate(value, { clientId }) {
        var existingId = clientId ? `&client_id=${clientId}` : '';
        return axios.get(`/client/validate-domain?domain=${encodeURIComponent(value)}${existingId}`)
            .then(r => r.data)
            .catch(r => false);
    },
    getMessage: (field, params, data) => field + ' is not unique ' + params[0],

}

export let udnInStockroomValidator = {
    validate(value) {
        return axios.get(`/stockroom/validate-in-stockroom?udn=${encodeURIComponent(value)}`)
            .then(r => r.data)
            .catch(r => false);
    },
    message: "UDN is not in stockroom",

}





export let udnValidator = {
    message: 'Not a valid UDN',
    validate: value => /^[0-9A-F]{8}$/i.test(value)
};

export let numberPlateValidator = {
    params: [],
    validate: value => /(^[A-Z]{2}[0-9]{2}\s?[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}[0-9]{1,4}$)|(^[0-9]{3}[DX]{1}[0-9]{3}$)/i.test(value),
    message: 'Number plate is not valid'
}


export let keycodeValidator = {
    params: [],
    validate: value => {
        if (process.env.VUE_APP_PROJECT_ACS == 'true')
            return /^[0-9A-F]{12}$/i.test(value);
        else
            return /^[0-9A-F]{8}$/i.test(value);
    },
    message: process.env.VUE_APP_PROJECT_ACS == 'true'
        ? 'Keycode is not 12 digit hex number'
        : 'Keycode is not 8 digit hex number',
}

export let startsWithValidator = {
    getMessage: (field, params, data) => field + ' does not start with ' + params[0],
    validate: (value, args) => new RegExp('^' + args[0]).test(value)
};

export let udnDeviceTypeValidator = {
    message: 'UDN is not the correct node type',
    params: ['nodeType', 'controllerType'],
    validate: (value, { nodeType, controllerType }) => {
        var udnStart = null;
        switch (nodeType) {
            case 'System Controller': udnStart = '00'; break;
            case 'Lock Controller': udnStart = '02'; break;
            case 'Power Hub': udnStart = '03'; break;
            case 'Reader Interface': udnStart = '05'; break;
            case 'Reader': udnStart = '04'; break;
            case 'Front Panel': udnStart = '06'; break;
            case 'SEM': udnStart = '09'; break;
            case 'Bus Expansion Module': udnStart = '0C'; break;
            default:
                // ACS
                switch (controllerType) {
                    case 'DC1': udnStart = '400'; break;
                    case 'DC2': udnStart = '410'; break;
                    case 'DC10': udnStart = '420'; break;
                    case 'IOM': udnStart = '500'; break;
                    case 'Communicator': udnStart = '510'; break;
                    default: break;
                }
                break;
        }

        if (udnStart)
            return new RegExp('^' + udnStart).test(value)

        return false;
    }
};




export let notInTwiceValidator = {
    getMessage: (field, params, data) => field + ' is a duplicate',
    message: '{_field_} is a duplicate',
    validate: (value, args) => {
        var count = 0;
        for (var i = 0; i < args.length; i++) {
            if (args[i] === value) {
                count++;
            }
        }
        return count <= 1;
    }
};

export let uniqueSimReferenceValidator = {
    params: ['simId'],
    validate(value, { simId }) {
        var existingId = simId ? `&simId=${simId}` : '';
        return axios.get(`/sim/validate-reference?reference=${encodeURIComponent(value)}${existingId}`)
            .then(r => r.data)
            .catch(r => false);
    },
    message: 'reference is not unique'
}

export let uniqueNumberPlateValidator = {
    params: ['fobId'],
    validate(value, { fobId }) {
        var existingId = fobId ? `&fob_id=${fobId}` : '';
        return axios.get(`/fob/validate-number-plate?number_plate=${encodeURIComponent(value)}${existingId}`)
            .then(r => r.data)
            .catch(r => false);
    },
    message: 'Number plate is not unique'
}

export let uniqueFobKeycodeValidator = {
    params: ['fobId'],
    validate(value, { fobId }) {
        var existingId = fobId ? `&fob_id=${fobId}` : '';
        return axios.get(`/fob/validate-keycode?keycode=${encodeURIComponent(value)}${existingId}`)
            .then(r => r.data)
            .catch(r => false);
    },
    message: 'Keycode is not unique'
}

export let uniqueFobReferenceValidator = {
    params: ['fobId'],
    validate(value, { fobId }) {
        var existingId = fobId ? `&fob_id=${fobId}` : '';
        return axios.get(`/fob/validate-reference?reference=${encodeURIComponent(value)}${existingId}`)
            .then(r => r.data)
            .catch(r => false);
    },
    message: 'Reference is not unique'
}

export let uniqueGroupNameValidator = {
    params: ['groupId', 'parentGroupId'],
    validate(value, { groupId, parentGroupId }) {
        var existingId = groupId ? `&groupId=${groupId}` : '';
        var parentGroup = parentGroupId ? `&parent=${parentGroupId}` : '';
        return axios.get(`/group/validate-name?name=${encodeURIComponent(value)}${parentGroup}${existingId}`).then(r => r.data).catch(r => false);
    },
    message: 'Group name is not unique in parent group'
}

export let uniqueNoticeMessageNameValidator = {
    params: ['noticeMessageId'],
    validate(value, { noticeMessageId }) {
        var existingId = noticeMessageId ? `&noticeMessageId=${noticeMessageId}` : '';
        return axios.get(`/noticemessage/validate-name?name=${encodeURIComponent(value)}${existingId}`).then(r => r.data).catch(r => false);
    },
    message: 'Message name is not unique'
}

export let uniqueNoticeBoardNameValidator = {
    params: ['noticeBoardId'],
    validate(value, { noticeBoardId }) {
        var existingId = noticeBoardId ? `&noticeBoardId=${noticeBoardId}` : '';
        return axios.get(`/noticeboard/validate-name?name=${encodeURIComponent(value)}${existingId}`).then(r => r.data).catch(r => false);
    },
    message: 'Notice board name is not unique'
}

export let uniqueTimeProfileNameValidator = {
    params: ['timeProfileId'],
    validate(value, { timeProfileId }) {
        var existingId = timeProfileId ? `&timeProfileId=${timeProfileId}` : '';
        return axios.get(`/timeprofile/validate-name?name=${encodeURIComponent(value)}${existingId}`).then(r => r.data).catch(r => false);
    },
    message: 'Time profile name is not unique'
}

export let uniqueAreaNameValidator = {
    params: ['areaId', 'parentAreaId'],
    validate(value, { areaId, parentAreaId }) {
        var existingId = areaId ? `&area_id=${areaId}` : '';
        var parentArea = parentAreaId ? `&parent=${parentAreaId}` : '';
        return axios.get(`/area/validate-name?name=${encodeURIComponent(value)}${parentArea}${existingId}`).then(r => r.data).catch(r => false);
    },
    message: 'Area name is not unique in parent area'
}


export let uniquePersonNameValidator = {
    params: ['personId', 'firstName', 'surname'],
    validate(value, { personId, firstName, surname }) {
        var existingId = personId ? `&person_id=${personId}` : '';
        return axios.get(`/person/validate-name?first_name=${encodeURIComponent(firstName)}&surname=${encodeURIComponent(surname)}${existingId}`).then(r => r.data).catch(r => false);
    },
    message: (fieldName) => `${fieldName} is not unique`,
}

export let uniquePersonEmailValidator = {
    params: ['personId', 'email'],
    validate(value, { personId, email }) {
        var existingId = personId ? `&person_id=${personId}` : '';
        return axios.get(`/person/validate-email?email=${encodeURIComponent(email)}${existingId}`).then(r => r.data).catch(r => false);
    },
    message: (fieldName) => `${fieldName} is not unique`,
}

export let uniqueDoorUnlockInvitationNameValidator = {
    params: ['doorUnlockInvitationId'],
    validate(value, { doorUnlockInvitationId }) {
        var existingId = doorUnlockInvitationId ? `&door_unlock_invitation_id=${doorUnlockInvitationId}` : '';
        return axios.get(`/doorunlockinvitation/validate-name?name=${encodeURIComponent(value)}${existingId}`).then(r => r.data).catch(r => false);
    },
    message: 'Door unlock invitation name is not unique'
}

export let uniqueInputNameValidator = {
    params: ['inputId', 'controllerId'],
    validate(value, { inputId, controllerId }) {
        var existingId = inputId ? `&input_id=${inputId}` : '';
        return axios.get(`/input/validate-name?name=${encodeURIComponent(value)}&controller_id=${controllerId}${existingId}`).then(r => r.data).catch(r => false);
    },
    message: 'Input name is not unique'
}

export let uniqueOutputNameValidator = {
    params: ['outputId', 'controllerId'],
    validate(value, { outputId, controllerId }) {
        var existingId = outputId ? `&output_id=${outputId}` : '';
        return axios.get(`/output/validate-name?name=${encodeURIComponent(value)}&controller_id=${controllerId}${existingId}`).then(r => r.data).catch(r => false);
    },
    message: 'Output name is not unique'
}

export let truthyValidator = {
    validate: (value) => !!value,
    message: (fieldName) => `${fieldName} is not valid`,
}

export let falsyValidator = {
    validate: (value) => !value,
    message: (fieldName) => `${fieldName} is not valid`,
}

export let propIsValidator = {
    params: ['prop', 'target'],
    validate: (value, { prop, target }) => value[prop] == target,
    message: (fieldName, placeholders) => `${startCase(placeholders.prop)} of ${fieldName} is not ${placeholders.target}`,
}

export let fobKeycodeExistValidator = {
    async validate(value) {
        try {
            var response = await axios.get(`/fob/find?keycode=${encodeURIComponent(value)}`);
            if (!response || !response.data) return false;

            return true;
        } catch (error) {
            return false;
        }
    },
    message: (fieldName) => `${fieldName} does not exist`,
}

export let passwordAtLeast8Validator = {
    validate: (value) => {
        if (typeof value != 'string') return false;

        return value.length >= 8;
    },
    message: (fieldName) => `${fieldName} must contains at least 8 characters`,
}

export let passwordNotCommonValidator = {
    params: ['commonPasswords'],
    validate: (value, { commonPasswords }) => {
        if (typeof value != 'string') return false;
        if (value.length == 0) return false;
        if (!commonPasswords) return true;

        return !commonPasswords.includes(value.toLowerCase());
    },
    message: (fieldName) => `${fieldName} must not be one of the common passwords`,
}

export let uniqueUserLinkValidator = {
    params: ['source', 'userLink'],
    validate(value, { source, userLink }) {
        var params = new URLSearchParams();
        params.set('source', source);
        if (userLink.userId) params.set('userId', userLink.userId);
        if (userLink.personId) params.set('personId', userLink.personId);
        if (userLink.alertPersonId) params.set('alertPersonId', userLink.alertPersonId);
        if (userLink.clientId) params.set('clientId', userLink.clientId);

        return axios.get(`/userlink/${userLink.userLinkId}/validate?${params}`)
            .then(response => response.data)
            .catch(() => false);
    },
    message(fieldName) {
        return `${fieldName} is already part of another link.`;
    },
}

export let arrayIncludeValidator = {
    params: ['array', 'message'],
    validate: (value, { array, message }) => {
        if (!array.includes(value))
            return false;
        else
            return true;
    },
    message(fieldName, { array, message }) {
        if (message)
            return message;
        else
            return `${fieldName} is not valid`;
    },
}